<template>
  <div class="tc-evaluate-adduser-155">
    <el-form
      :model="measureForm"
      status-icon
      :rules="rules"
      ref="measureForm"
      size="mini"
    >
      <el-form-item label="分组名称：" label-width="150px" prop="name">
        <el-input
          v-model="measureForm.name"
          size="small"
          autocomplete="off"
          clearable
          class="input-width"
        ></el-input>
      </el-form-item>
      <el-form-item label="分组状态：" label-width="150px" prop="status">
        <el-radio v-model="measureForm.status" label="1">打开</el-radio>
        <el-radio v-model="measureForm.status" label="2">关闭</el-radio>
      </el-form-item>
      <el-form-item
        label="量表权限设置："
        label-width="150px"
        prop="measure_name"
        class="scaledata"
      >
        <div class="query">
          <el-form :inline="true" :model="measureForm" size="mini">
            <el-form-item>
              <el-input
                v-model="measureForm.search"
                placeholder="量表名称"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryScale">量表查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="checkAll(1)"
                >全选或取消所有量表</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="checkAll(2)"
                >全选或取消测评人查看结果</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- 所有量表 -->
        <div class="table" @change="handleCheckAllChange">
          <table>
            <tr>
              <th>量表名称</th>
              <th>使用该量表</th>
              <th>允许测评人看测评结果</th>
              <th>测评次数</th>
            </tr>
            <!-- ----------------------- -->
            <template v-for="item in scaleData">
              <tr
                class="title"
                :key="item.id"
                v-if="item.status_measure.length > 0"
              >
                <td colspan="4">{{ item.classify_name }}</td>
              </tr>
              <tr v-for="item2 in item.status_measure" :key="item2.measure_title">
                <td>{{ item2.measure_title }}</td>
                <td>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox :label="item2.id" name="a"></el-checkbox>
                  </el-checkbox-group>
                </td>
                <td>
                  <el-checkbox-group v-model="checkList2">
                    <el-checkbox :label="item2.id" name="b"></el-checkbox>
                  </el-checkbox-group>
                </td>
                <td>
                  <el-input
                    v-model="item2.max_num"
                    placeholder=""
                    size="mini"
                    :name="item2.id"
                    maxlength="2"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                  ></el-input>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </el-form-item>
      <el-form-item
        label="已测量表是否显示："
        label-width="150px"
        prop="measure_name"
      >
        <el-radio v-model="measureForm.showTested" label="1">显示</el-radio>
        <el-radio v-model="measureForm.showTested" label="2">不显示</el-radio>
      </el-form-item>
      <el-form-item label="备注：" label-width="150px" prop="measure_name">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入备注信息"
          class="input-textarea-width"
          v-model="measureForm.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="addUserBtn" @click="submit">
      <img src="@/assets/images/addbtn.png" alt />
    </div>
  </div>
</template>

<script>
import {getMeasureByClassify,getMeasureGroupDetail,addMeasureGroup,updateMeasureGroup,} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      isUpdate: false,
      scaleData: [], // 存放量表列表的合子
      backupMeasure: [], // 存放量表列表的合子
      rules: {
        name: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
      },
      // 所有量表-是否有查看权限
      measureForm: {
        id: null,
        rules: {},
        search: null,
        name: "", // 分组名
        status: "1",
        measureList: "",
        showTested: "1",
        remark: "",
        measureIdsTemp: {}, // 默认选中
        measureIds: [], // 默认选中
        showResTemp: {}, // 默认选中
        measure: [],
      },
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 所有量表-全选控制
      checkAll1: 1,
      checkAll2: 1,
    };
  },
  created() {
    this.loadScaleData();
  },
  methods: {
    init() {
      if (this.$route.query.id === undefined) return false;
      this.isUpdate = true;
      let param = {
        id: this.$route.query.id,
      };
      this.measureForm.id = this.$route.query.id;
      getMeasureGroupDetail(param).then(async (res) => {
        //console.log(res);
        this.measureForm.name = res.data.group_name;
        this.measureForm.status = res.data.status + "";
        this.measureForm.showTested = res.data.show_finish_measure + "";
        this.measureForm.remark = res.data.remark;
        this.measureForm.measure = res.data.measure;
        // this.measureForm.showRes = res.data.show_res;
        //  处理量表
        if (res.data.measure.length > 0) {
          res.data.measure.forEach((v) => {
            this.checkList.push(v.measure_id);
            if (v.show_result == 1) {
              this.checkList2.push(v.measure_id);
            }
          });
        }
        // 次数
        this.scaleData.forEach((v) => {
          if (v.status_measure.length > 0) {
            v.status_measure.forEach((v2) => {
              res.data.measure.forEach((v3) => {
                if (v2.id == v3.measure_id) {
                  this.$set(v2, "max_num", v3.max_num || 0);
                }
              });
            });
          }
        });
      });
    },
    submit() {
      // console.log(this.measureForm.measure);
      // return
      let param = {
        group_name: this.measureForm.name,
        status: this.measureForm.status,
        show_finish_measure: this.measureForm.showTested,
        remark: this.measureForm.remark,
        measure: this.measureForm.measure,
      };
      //console.log("param", param);
      this.$refs["measureForm"].validate((valid) => {
        if (valid) {
          if (this.isUpdate === true) {
            // 更新
            Object.assign(param, {
              id: this.measureForm.id,
            });
            //console.log(param);
            updateMeasureGroup(param).then(async (res) => {
              if (res.code === 400200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$router.go(-1);
              }
            });
          } else {
            // 添加
            //console.log(param);
            addMeasureGroup(param).then((res) => {
              if (res.code === 400200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$router.go(-1);
              }
            });
          }
        } else {
          return;
        }
      });

      //console.log("12321");
    },
    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      if (val.target.name === "a") {
        var obj = { measure_id: a, show_result: "" || 0 };
        var tmp = this.measureForm.measure.some(function (item) {
          return item.measure_id === a;
        });
        if (tmp) {
          this.measureForm.measure = this.measureForm.measure.filter((v) => {
            return v.measure_id != val.target.value;
          });
        } else {
          this.measureForm.measure.push(obj);
        }
      } else if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项!");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
        var index = this.measureForm.measure.findIndex((item) => {
          return item.measure_id === a;
        });
        if (this.measureForm.measure[index] && index != -1) {
          if (this.measureForm.measure[index].show_result === 0) {
            this.measureForm.measure[index].show_result = 1;
          } else {
            this.measureForm.measure[index].show_result = 0;
          }
        }
      } else {
        let st = true;
        this.measureForm.measure.forEach((v) => {
          if (v.measure_id == val.target.name) {
            v.max_num = val.target.value || 0;
            st = false;
          }
        });
        if (st) {
          this.$message({
            message: "警告,量表未勾选",
            type: "warning",
          });
          val.target.value = "";
        }
      }
    },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res === 1) {
        if (this.checkAll1 === 0) {
          this.checkAll1 = 1;
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { measure_id: v.id, show_result: 0 };
              this.measureForm.measure.push(obj);
            });
          });
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.measureForm.measure = [];
          this.checkAll1 = 0;
        }
      } else {
        if (this.checkAll2 === 1) {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.measureForm.measure.length; i++) {
              if (this.measureForm.measure[i].measure_id === v) {
                this.measureForm.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.measureForm.measure.length; i++) {
              if (this.measureForm.measure[i].measure_id === v) {
                this.measureForm.measure[i].show_result = 0;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 量表数据请求
    async loadScaleData() {
       var res = await getMeasureByClassify();
      if (res.code === 400200) {
        this.scaleData = res.data;
        this.scaleData1 = res.data;
        this.init();
      } else {
        return;
      }
    },
    // 量表查询
    queryScale() {
      //console.log(1);
      var str = this.measureForm.search;
      if (str === "") {
        this.scaleData = this.scaleData1;
        return;
      }
      var obj = [{ classify_name: "查询结果", id: 1, status_measure: [] }];
      for (let k in this.scaleData1) {
        if (this.scaleData1[k].id) {
          this.scaleData1[k].status_measure.forEach((v) => {
            if (v.measure_title.indexOf(str) != -1) {
              obj[0].status_measure.push(v);
            }
          });
        }
      }
      this.scaleData = obj;
    },
  },
  watch: {},
};
</script>

<style lang="less">
.tc-evaluate-adduser-155 {
  .scaledata {
    .el-form-item__content {
      line-height: 25px;
    }
  }
  .table {
    width: 950px;
    height: 300px;
    overflow: auto;
    border: 1px #cccccc solid;
    margin-bottom: 20px;
    .el-input__inner {
      width: 120px;
      padding-right: 20px;
    }

    table {
      width: 945px;
      text-align: center;
      overflow: auto;
      border-collapse: collapse;
      th {
        border-bottom: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
      }
      tr,
      th {
        height: 20px;
        padding: 0;
        font-weight: 300;
        font-size: 14px;
      }
      tr {
        td {
          padding: 2px 5px;
          &:nth-child(1) {
            text-align: left;
          }
          .el-input-number {
            .el-input__inner {
              padding: 0;
            }
          }
        }
        &:hover {
          background: #b4e7e9;
        }
      }
      th {
        height: 30px;
        font-size: 15px;
        background: rgb(224, 245, 250);
      }
      .title {
        background: rgb(224, 245, 250);
        text-align: left;
        font-weight: 700;
        font-size: 15px;
      }
      .el-checkbox__label {
        display: none;
      }
    }
  }
  .query {
    p {
      line-height: 30px;
      font-size: 14px;
      color: #606266;
      margin-bottom: 10px;
      padding-left: 10px;
    }
  }
  .input-width {
    width: 250px;
  }
  .input-textarea-width {
    width: 350px;
  }
  .addUserBtn {
    margin-top: 20px;
    text-align: center;
    img {
      width: 100px;
    }
  }
}
</style>